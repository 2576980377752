.Toggle {
    border: none !important;
}

.BlurBackground {
    background: rgba(255, 255, 255, 0.6) !important;
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(9px);
}

.Black {
    color: #343a40;
}

.BorderRight {
    border-right: 1px solid #343a40;
}

.Title {
    font-family: "Roboto Condensed", sans-serif;
    margin: 0;
    padding: 0 0 0 4px;
    font-size: 1rem;
    margin-left: 55px;
    color: #343a40;
}

.Top {
    position: absolute;
    top: 13px;
}

.CenterNavItem {
    text-align: center;
}

.Bottom {
    position: absolute;
    top: 28px;
}

.NoTransition {
    /*CSS transitions*/
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    /*CSS transforms*/
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    /*CSS animations*/
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
}
/* @media (min-width: 992px) {
    .Title {
        padding: 0 0 0 4px;
        font-size: 2rem;
        margin-left: 80px;
    }

    .Top {
        top: 20px;
    }

    .Bottom {
        top: 52px;
    }
} */
