/* <span>Photo by <a href="https://unsplash.com/@scottwebb?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Scott Webb</a> on <a href="https://unsplash.com/s/photos/school-building?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span> */
.Bg {
    background-image: url(../../assets/img/school.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    max-height: 550px;
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}

.BgTop {
    background-image: radial-gradient(
            circle 5px at top left,
            rgba(92, 212, 241, 0.2) 0%,
            rgba(144, 219, 238, 0.3) 50%,
            rgba(204, 28, 28, 0.1) 50%,
            rgba(204, 28, 28, 0.1) 30%,
            transparent 30%,
            transparent 50%
        ),
        linear-gradient(90deg, rgba(199, 117, 117, 0), rgba(199, 117, 117, 0));
    background-size: 11px 11px;
    background-repeat: repeat;
    animation: slide 20s linear infinite;
    height: 550px;
}

.Head {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 3rem;
    margin-top: 200px;
}

.Bottom {
    margin-bottom: 150px;
}

.Size {
    max-width: 100%;
}

@media (min-width: 992px) {
    .Size {
        max-width: 90%;
    }

    .Head {
        font-size: 4rem;
    }
}

@keyframes slide {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 -200px;
    }
}

/* Animation */

@keyframes FadeInUp {
    from {
        transform: translate3d(0, 40px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@-webkit-keyframes FadeInUp {
    from {
        transform: translate3d(0, 40px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.Animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.AnimatedChild {
    animation-delay: 0.3s;
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.3s;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.AnimatedFadeInUp {
    opacity: 0;
}

.FadeInUp {
    opacity: 0;
    animation-name: FadeInUp;
    -webkit-animation-name: FadeInUp;
}
