@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.NavBar_Toggle__2mn2S {
    border: none !important;
}

.NavBar_BlurBackground__221Ik {
    background: rgba(255, 255, 255, 0.6) !important;
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(9px);
}

.NavBar_Black__42Rjv {
    color: #343a40;
}

.NavBar_BorderRight__pbUuW {
    border-right: 1px solid #343a40;
}

.NavBar_Title__324_X {
    font-family: "Roboto Condensed", sans-serif;
    margin: 0;
    padding: 0 0 0 4px;
    font-size: 1rem;
    margin-left: 55px;
    color: #343a40;
}

.NavBar_Top__3aDLL {
    position: absolute;
    top: 13px;
}

.NavBar_CenterNavItem__2rrfK {
    text-align: center;
}

.NavBar_Bottom__3UqyW {
    position: absolute;
    top: 28px;
}

.NavBar_NoTransition__2W7xo {
    /*CSS transitions*/
    transition-property: none !important;
    /*CSS transforms*/
    transform: none !important;
    /*CSS animations*/
    animation: none !important;
}
/* @media (min-width: 992px) {
    .Title {
        padding: 0 0 0 4px;
        font-size: 2rem;
        margin-left: 80px;
    }

    .Top {
        top: 20px;
    }

    .Bottom {
        top: 52px;
    }
} */



.Home_Dots__1oU74 {
    border-bottom: dotted 3px #24abee;
    max-width: 220px;
    margin: auto;
}

.Home_CardImg__1NdjO {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.Home_MarginTop__3E14S {
    margin-top: 60px !important;
}

.Home_Img__lF0ye {
    border-radius: 120px;
    display: block;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.Home_Hov__1zXuC {
    box-shadow: 0 0 7px rgba(33, 33, 33, 0.2) !important;
    transition: box-shadow 0.2s ease-in-out 0s;
}

.Home_Hov__1zXuC:hover {
    box-shadow: 0 0 20px rgba(33, 33, 33, 0.3) !important;
}

.Home_HovText__2EKf1:hover {
    text-decoration: none !important;
}

.Home_Mobile__gbTfM {
    text-align: center;
}

@media (min-width: 992px) {

    .Home_Mobile__gbTfM {
        text-align: left;
    }
}

/* <span>Photo by <a href="https://unsplash.com/@scottwebb?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Scott Webb</a> on <a href="https://unsplash.com/s/photos/school-building?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span> */
.Profil_Bg__39yi0 {
    background-image: url(/static/media/profil.fdccfe45.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    max-height: 550px;
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}

.Profil_Head__3e-mZ {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 3rem;
    margin-top: 200px;
}

.Profil_Bottom__1cJpm {
    margin-bottom: 150px;
}

.Profil_TitleFont__dY9fC {
    font-family: "Roboto Condensed", sans-serif;
}

@media (min-width: 992px) {
    .Profil_Head__3e-mZ {
        font-size: 4rem;
    }
}

/* Animation */

@keyframes Profil_FadeInUp__3gznD {
    from {
        transform: translate3d(0, 40px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.Profil_Animated__3vJ_n {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.Profil_AnimatedChild__1OuDo {
    animation-delay: 0.3s;
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.3s;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.Profil_AnimatedFadeInUp__3_Mqf {
    opacity: 0;
}

.Profil_FadeInUp__3gznD {
    opacity: 0;
    animation-name: Profil_FadeInUp__3gznD;
    -webkit-animation-name: Profil_FadeInUp__3gznD;
}

.ProfilParsial_DivImg__3wV_5 {
    max-width: 100%;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.ProfilParsial_DivImg__3wV_5 img {
    height: 100%;
}

.ProfilParsial_PaddingLeft__2hufL {
    padding-left: 0;
}

.ProfilParsial_PaddingRight__2cet5 {
    padding-right: 0;
}

@media (min-width: 992px) {
    .ProfilParsial_PaddingLeft__2hufL {
        padding-left: 8%;
    }

    .ProfilParsial_PaddingRight__2cet5 {
        padding-right: 8%;
    }
}

/* <span>Photo by <a href="https://unsplash.com/@sandeepdelineates?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">sandeep gill</a> on <a href="https://unsplash.com/s/photos/university?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span> */

.Visi_Bg__1_L3W {
    background-image: url(/static/media/visi.41caa0ee.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    max-height: 550px;
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}

.Visi_Head__1Hbpq {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 3rem;
    margin-top: 200px;
}

.Visi_Bottom__24WRQ {
    margin-bottom: 150px;
}

.Visi_TitleFont__3nDdD {
    font-family: "Roboto Condensed", sans-serif;
}

.Visi_MarginLeft__3-Cq5 {
    margin-left: 0;
}

@media (min-width: 992px) {
    .Visi_Head__1Hbpq {
        font-size: 4rem;
    }

    .Visi_MarginLeft__3-Cq5 {
        margin-left: 10%;
    }
}

/* Animation */

@keyframes Visi_FadeInUp__llV0m {
    from {
        transform: translate3d(0, 40px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.Visi_Animated__ZcsPV {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.Visi_AnimatedChild__2nSJM {
    animation-delay: 0.3s;
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.3s;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.Visi_AnimatedFadeInUp__18XHA {
    opacity: 0;
}

.Visi_FadeInUp__llV0m {
    opacity: 0;
    animation-name: Visi_FadeInUp__llV0m;
    -webkit-animation-name: Visi_FadeInUp__llV0m;
}

.VisiImg_Img__9tCm6 {
    width: 100%;
    height: 100%;
}
/* <span>Photo by <a href="https://unsplash.com/@scottwebb?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Scott Webb</a> on <a href="https://unsplash.com/s/photos/school-building?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span> */
.Header_Bg__19DVN {
    background-image: url(/static/media/school.aa4782ca.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    max-height: 550px;
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}

.Header_BgTop__3Qk3V {
    background-image: radial-gradient(
            circle 5px at top left,
            rgba(92, 212, 241, 0.2) 0%,
            rgba(144, 219, 238, 0.3) 50%,
            rgba(204, 28, 28, 0.1) 50%,
            rgba(204, 28, 28, 0.1) 30%,
            transparent 30%,
            transparent 50%
        ),
        linear-gradient(90deg, rgba(199, 117, 117, 0), rgba(199, 117, 117, 0));
    background-size: 11px 11px;
    background-repeat: repeat;
    animation: Header_slide__2LcKo 20s linear infinite;
    height: 550px;
}

.Header_Head__2LaiT {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 3rem;
    margin-top: 200px;
}

.Header_Bottom__1fXzV {
    margin-bottom: 150px;
}

.Header_Size__1i6HT {
    max-width: 100%;
}

@media (min-width: 992px) {
    .Header_Size__1i6HT {
        max-width: 90%;
    }

    .Header_Head__2LaiT {
        font-size: 4rem;
    }
}

@keyframes Header_slide__2LcKo {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 -200px;
    }
}

/* Animation */

@keyframes Header_FadeInUp__1SD7z {
    from {
        transform: translate3d(0, 40px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.Header_Animated__1zWR8 {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.Header_AnimatedChild__1zaST {
    animation-delay: 0.3s;
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.3s;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.Header_AnimatedFadeInUp__21cpM {
    opacity: 0;
}

.Header_FadeInUp__1SD7z {
    opacity: 0;
    animation-name: Header_FadeInUp__1SD7z;
    -webkit-animation-name: Header_FadeInUp__1SD7z;
}

.Post_Dots__1SfsW {
    border-bottom: dotted 3px #24abee;
    max-width: 220px;
    margin: auto;
}

.Post_CardImg__6H_kW {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.Post_Bg__impLX {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
}

.Post_MarginTop__3WL4q {
    margin-top: 60px !important;
}

.Post_Fill__3y2iF {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    height: 300px;
    padding: auto 0;
    background-color: #000;
}

.Post_Fill__3y2iF img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    opacity: 0.6;
}

.Post_Hov__fQAnM {
    box-shadow: 0 0 7px rgba(33, 33, 33, 0.2) !important;
    transition: box-shadow 0.2s ease-in-out 0s;
}

.Post_Hov__fQAnM:hover {
    box-shadow: 0 0 20px rgba(33, 33, 33, 0.2) !important;
}

.Post_HovText___0I3z:hover {
    text-decoration: none !important;
}

.Post_Mobile__r3_le {
    text-align: center;
}

.Post_Title__1p5Ih {
    position: absolute;
    top: 250px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    padding: 10px;
}

@media (min-width: 992px) {
    .Post_Mobile__r3_le {
        text-align: left;
    }

    .Post_Fill__3y2iF {
        align-items: center;    
    }
}

/* Animation */

@keyframes Post_FadeInUp__l_02R {
    from {
        transform: translate3d(0, 40px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.Post_Animated__1ZL6Y {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.Post_AnimatedFadeInUp__2IXqd {
    opacity: 0;
}

.Post_FadeInUp__l_02R {
    opacity: 0;
    animation-name: Post_FadeInUp__l_02R;
    -webkit-animation-name: Post_FadeInUp__l_02R;
}

.Footer_Img__36iUq {
    width: 70px;
    position: absolute;
    margin: auto;
    top: 0px;
    bottom: 20px;
    left: 0;
    right: 0;
}

.Footer_LeftMar__2J4fE {
    margin-left: 22px;
}

@media (min-width: 992px) {
    .Footer_LeftMar__2J4fE {
        margin-left: 0px;
    }
}

